import Topnev from '@/components/Topnev.vue';
import Botnev from '@/components/Botnev.vue';
//数组存放接收的组件名
const components = [Topnev,Botnev]
export default {
  install(Vue, option) {
    components.forEach(component => {
      Vue.component(component.name, component);
    });
  }
}