import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ChooseCar from '../views/ChooseCar.vue'
import CarDetail from '../views/CarDetail.vue'
import ActiveList from '../views/ActiveList.vue'
import BrandView from '../views/BrandView.vue'
Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {title: '首页'}
  },
  {
    path: '/about',
    name: 'about',
    component: () => import( /* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: {title: '首页'}
  },
  {
    path: '/ChooseCar',
    name: 'ChooseCar',
    component: ChooseCar,
    meta: {title: '首页'}
  },
  {
    path: '/CarDetail',
    name: 'CarDetail',
    component: CarDetail,
    meta: {title: '车辆详情'}
  },
  {
    path: '/ActiveList',
    name: 'ActiveList',
    component: ActiveList
  },
  {
    path: '/BrandView',
    name: 'BrandView',
    component: BrandView,
    meta: {title: '品牌活动'}
  },
  {
    path: '/CarNews',
    name: 'CarNews',
    component: () => import('../views/CarNews.vue'),
    meta: {title: '车市新闻'}
  }, , , {
    path: '/BrandDetail',
    name: 'BrandDetail',
    component: () => import('../views/BrandDetail.vue'), // 活动详情
    meta: {title: '品牌活动详情'}
  }, {
    path: '/Successful',
    name: 'Successful',
    component: () => import('../views/Successful.vue'), // 成功案例
    meta: {title: '成功案例'}
  }, {
    path: '/SuccessDetail',
    name: 'SuccessDetail',
    component: () => import('../views/SuccessDetail.vue'), // 富文本
    meta: {title: '成功案例详情'}
  },

  // 车市新闻详情
  {
    path: '/CarNewsDetail',
    name: 'CarNewsDetail',
    component: () => import('../views/CarNewsDetail.vue'),
    meta: {title: '车市新闻详情'}
  },
  // 关于我们/*  */
  {
    path: '/AboutUs',
    name: 'AboutUs',
    component: () => import('../views/AboutUs.vue'),
    meta: {title: '关于我们'}
  },
  // 人才招聘
  {
    path: '/Recruit',
    name: 'Recruit',
    component: () => import('../views/Recruit.vue'),
    meta: {title: '人才招聘'}
  },
  // 人才招聘详情
  {
    path: '/RecruitDetail/:id',
    name: 'RecruitDetail',
    component: () => import('../views/RecruitDetail.vue'),
    meta: {title: '人才招聘详情'}
  },

]

const router = new VueRouter({
  routes
})

router.afterEach(to => {
  document.title = to.meta.title
})
export default router
