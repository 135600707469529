<template>
  <div class="home">
    <Topnev :checked="'首页'"></Topnev>
    <div class="allflex">
      <!-- 轮播图 -->
      <div class="banner">
        <el-carousel height="600px">
          <el-carousel-item v-for="(item, index) in bannerList" :key="index">
            <img :src="item.image" alt="" style="width: 100%; height: 100%;"/>
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- 背景图 -->
      <div class="bgimg">
        <div class="bgcenter">
          <div class="cartop flexalign">
            <div class="carleft flexalign">
              <el-popover
                placement="bottom"
                :visible-arrow="false"
                v-model="topShowCity"
                trigger="click">
                <div class="carleft flexalign" slot="reference" :style="{'font-size':'calc(23px - ' +  form.city_name.length + 'px )'}">{{form.city_name}}<img src="@/assets/Group 1.png" alt="" style="width: 24px; height: 24px;" /></div>

                  <div class="citys">
                    <div class="citys-left">
                      <div class="citys-left-name" :class="{'citys-left-checked': navgatorIndex == -1}" @click="handleLeft(-1)">热</div>
                      <div class="citys-left-name" :class="{'citys-left-checked': navgatorIndex == i}" v-for="(s,i) in citysList" :key="i" @click="handleLeft(i)">{{s.name}}</div>
                    </div>
                    <div class="citys-right">
                      <div class="citys-right-box" ref="topcitys">

                        <div class="citys-right-title" :id="'ids-1'">热门</div>
                        <div class="citys-right-name">
                          <div class="citys-right-city" :class="{'citys-right-city-checked': form.city_id == t.city.id}" v-for="(t,i) in hotCitysList" :key="i" @click="handleSelect(t.city.id, t.city.name)">{{t.city.name}}</div>
                        </div>

                        <div :id="'ids'+ind" v-for="(its, ind) in citysList" :key="ind">
                          <div class="citys-right-title">{{its.name}}</div>
                          <div class="citys-right-name">
                            <div class="citys-right-city" :class="{'citys-right-city-checked': form.city_id == tt.id}" v-for="(tt,ii) in its.city" :key="ii"  @click="handleSelect(tt.id, tt.name)">{{tt.name}}</div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
              </el-popover>
            </div>
            <div class="carright">
              <el-carousel height="64px" direction="vertical">
                <el-carousel-item v-for="(item, index) in enquiryList" :key="index">
                  <div class="carright-box"><span>{{item.name}}</span> <span>{{item.createtime}}前成功询价</span> <span
                      class="carright-car">{{item.car_type}}</span></div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
          <div class="carter flexalign">
            <img :src="carInfo.image" alt="" v-if="carInfo.image" style="width: 172px; height: 172px;">
            <img src="@/assets/Frame 36.png" alt="" v-else style="width: 172px; height: 172px;"/>
            <div class="terall">
              <div v-if="carInfo.name">{{carInfo.name}}</div>
              <div v-else>请选择车型</div>
            </div>
            <div class="catchoose flexalign" @click="tochoose">
              <div>选择车型</div>
              <img src="@/assets/Frame (3).png" alt="" />
            </div>
          </div>
          <div class="formter">
            <el-form :model="form" :rules="rules" ref="form">
              <el-form-item prop="name">
                <div class="formbar flexalign" >
                  <div class="name">姓名</div>
                  <el-input placeholder="请输入姓名" v-model="form.name" clearable>
                  </el-input>
                </div>
              </el-form-item>

              <el-form-item prop="phone">
                <div class="formbar flexalign">
                  <div class="name">电话号码</div>
                  <el-input placeholder="请输入电话号码" v-model="form.phone" clearable>
                  </el-input>
                </div>
              </el-form-item>

              <el-form-item prop="code">
                <div class="formbar flexalign">
                  <div class="name">验证码</div>
                  <el-input placeholder="请输入验证码" v-model="form.code" clearable>
                  </el-input>
                  <div class="code" v-if="canClick == true" @click="countdowm" v-text="content "></div>
                  <div class="code" v-else v-text="content "></div>
                </div>
              </el-form-item>

              <el-form-item>
                <div class="formbar flexalign">
                  <div class="name">购车城市</div>
                    <el-popover style="width: 100%;"
                      placement="bottom"
                      :visible-arrow="false"
                      v-model="showCity"
                      trigger="click">
                        <el-input slot="reference" placeholder="请选择购车城市" :readonly="true" v-model="form.city_name" clearable>
                          <img slot="suffix" style="margin-top: 10px;margin-right: 10px;width: 16px;height: 16px;" src="@/assets/Frame (4).png" alt="" />
                        </el-input>

                        <div class="citys">
                          <div class="citys-left">
                            <div class="citys-left-name" :class="{'citys-left-checked': navgatorIndex == -1}" @click="handleLeft(-1)">热</div>
                            <div class="citys-left-name" :class="{'citys-left-checked': navgatorIndex == i}" v-for="(s,i) in citysList" :key="i" @click="handleLeft(i)">{{s.name}}</div>
                          </div>
                          <div class="citys-right">
                            <div class="citys-right-box" ref="citys">

                              <div class="citys-right-title" :id="'id-1'">热门</div>
                              <div class="citys-right-name">
                                <div class="citys-right-city" :class="{'citys-right-city-checked': form.city_id == t.city.id}" v-for="(t,i) in hotCitysList" :key="i" @click="handleSelect(t.city.id, t.city.name)">{{t.city.name}}</div>
                              </div>

                              <div :id="'id'+ind" v-for="(its, ind) in citysList" :key="ind">
                                <div class="citys-right-title">{{its.name}}</div>
                                <div class="citys-right-name">
                                  <div class="citys-right-city" :class="{'citys-right-city-checked': form.city_id == tt.id}" v-for="(tt,ii) in its.city" :key="ii"  @click="handleSelect(tt.id, tt.name)">{{tt.name}}</div>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                    </el-popover>
                </div>
              </el-form-item>
              <el-form-item>
                <div class="submit" @click="handleSubmit('form')">{{btnTxt}}</div>
                <div class="tip">
                  <el-checkbox v-model="istrue" @change="handleReady"></el-checkbox>
                  我已阅读并同意<span class="tip1"  @click="handleAggre">《用户隐私保护协议》</span>
                </div>
              </el-form-item>
            </el-form>
            <el-dialog
              title="选择支付方式"
              :visible.sync="centerDialogVisible"
              width="30%"
              :before-close="closepops"
              center>
              <div class="price-box">
                <div>金额 <span class="money">¥{{price}}</span></div>
                <div class="box-type">
                  <el-radio v-model="form.type" label="1" @input="handlePayType">微信</el-radio>
                  <el-radio v-model="form.type" label="2" @input="handlePayType">支付宝</el-radio>
                </div>
                <img v-show="qrImgUrl" :src="qrImgUrl" alt="" style="width: 200px; height: 200px; object-fit: contain;">
              </div>
            </el-dialog>
            <el-dialog
              :visible.sync="aggreDialogVisible"
              width="70%"
              center>
              <div v-html="serviceAggre"></div>
            </el-dialog>
          </div>
        </div>
      </div>
      <!-- 底图 -->
      <div class="last">
        <div class="last1" v-html="aboutUs"></div>
        <div class="lastbot flexbetton">
          <div class="last2">
            <div class="txt2">服务说明</div>
            <div class="txt3">Service Description</div>
            <div class="txt4" v-html="serviceDesc"></div>
          </div>
          <div class="last2 last3">
            <div class="txt2">服务保障</div>
            <div class="txt3">Service Description</div>
            <div class="txt4" v-html="serviceEnsure"></div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="succesVisible" width="444px" :show-close="false">
      <div class="allpop">
        <div class="succpop">
          <div class="succ1">
            报价信息已发送到您的手机，如有问题 可拨打热线电话
          </div>
          <div class="succ2">{{active.phone}}</div>
          <div class="succ3">上午9：00-12：00，下午13：00-19：00</div>
          <div class="succ4" @click="confirm">确定</div>
          <div class="customer"><img :src="customerImg" alt=""></div>
        </div>
        <div class="succimg">
          <img :src="active.active.image" alt="" />
          <div class="succbtn" @click="onactive(active.active)">进入活动</div>
          <div class="succclose" @click="closepop">
            <img src="@/assets/ic-clear.png" alt="" />
          </div>
        </div>

      </div>
    </el-dialog>
    <Botnev></Botnev>
  </div>
</template>

<script>
  // @ is an alias to /src
  import QRCode from 'qrcode'
  import HelloWorld from "@/components/HelloWorld.vue";
  import Topnev from "@/components/Topnev.vue";
  import {
    homeBanner,
    homeEnquiry,
    citys,
    hotCitys,
    captche,
    orderAdd,
    carPrice,
    getText,
    orderInfo,
    carActive,
    customer
  } from "@/untils/api";

  export default {
    name: "HomeView",
    components: {
      HelloWorld,
      Topnev,
    },
    data() {
      return {
        form: {
          name: '',
          phone: '',
          code: '',
          city_id: 0,
          city_name: '',
          pay_type: 1,
          is_scan: 1,
          type: ''
        },
        rules: {
          name: [{required: true, message: '请输入姓名'}],
          phone: [{required: true, message: '请输入手机号'}],
          code: [{required: true, message: '请输入验证码'}]
        },
        price: 0,
        istrue: false,
        bannerList: [],
        enquiryList: [],
        citysList: [],
        hotCitysList: [],
        navgatorIndex: -1,
        listBoxState: true, //点击导航栏时，暂时停止监听页面滚动
        showCity: false,
        topShowCity: false,
        carInfo: {},
        content: "发送验证码",
        canClick: true,
        refreshData: null,
        centerDialogVisible: false,
        aggreDialogVisible: false,
        succesVisible: false,
        qrImgUrl: '',
        aboutUs: '',
        serviceDesc: '',
        serviceEnsure: '',
        serviceAggre: '',
        orderId: 0,
        active: {
          active: {}
        },
        btnTxt: '立即报价',
        customerImg: ''
      };
    },
    created() {
      let car = this.$store.getters.carInfo
      this.carInfo = car
      // 轮播图
      homeBanner().then(res => {
        this.bannerList = res.data
      }).catch(err => {
        this.$message.error(err);
      })
      // 询价
      homeEnquiry().then(ret => {
        this.enquiryList = ret.data
      }).catch(err => {
        this.$message.error(err);
      })
      // 城市信息
      citys().then(res => {
        this.citysList = res.data
      }).catch(err => {
        this.$message.error(err);
      })
      // 热门城市
      hotCitys().then(ss => {
        this.hotCitysList = ss.data
        if (this.form.city_name == '') {
          this.form.city_id = ss.data[0].city_id;
          this.form.city_name = ss.data[0].city.name
          // 保存vuex
          this.$store.commit('SET_FORM_CITY', {city_id: ss.data[0].city_id, city_name: ss.data[0].city.name});
        }
      }).catch(err => {
        this.$message.error(err);
      })
      // 关于我们
      getText({id: 9}).then(tmp => {
        this.aboutUs = tmp.data.content
      }).catch(err => {
        this.$message.error(err);
      })
      // 服务说明
      getText({id: 11}).then(tps => {
        this.serviceDesc = tps.data.content
      }).catch(err => {
        this.$message.error(err);
      })
      // 服务保障
      getText({id: 10}).then(ser => {
        this.serviceEnsure = ser.data.content
      }).catch(err => {
        this.$message.error(err);
      })
      // 协议
      getText({id: 4}).then(agr => {
        this.serviceAggre = agr.data.content
      }).catch(err => {
        this.$message.error(err);
      })
      let carId = JSON.parse(localStorage.getItem('form')).car_type;
      if (carId) {
        // 获取活动
        carActive({car_id: carId}).then(tmp => {
          this.active = tmp.data
        }).catch(err => {
          this.$message.error(err);
        })
      }
    },
    mounted() {
      // 获取指定元素
      const scrollview = this.$refs.citys;
      // 添加滚动监听，该滚动监听了拖拽滚动条
      scrollview.addEventListener('scroll', this.scrollToTop, true)

      // 获取指定元素
      const topscrollview = this.$refs.topcitys;
      // 添加滚动监听，该滚动监听了拖拽滚动条
      topscrollview.addEventListener('scroll', this.topScrollToTop, true)
      this.$forceUpdate()
    },
    methods: {
      // 点击导航菜单，页面滚动到指定位置
      handleLeft(index) {
        this.navgatorIndex = index;

        let top = document.getElementById(`id${index}`).offsetTop;
        this.$refs.citys.scrollTop = top;

        let topcitys = document.getElementById(`ids${index}`).offsetTop;
        this.$refs.topcitys.scrollTop = topcitys;
      },
      // 滚动选中
      scrollToTop() {
        let scrollTop = this.$refs.citys.scrollTop;

        // 热门选中
        var hotTop = document.getElementById(`id-1`).offsetTop;
        var hotHeight = document.getElementById(`id0`).scrollHeight;
        if (scrollTop <= hotHeight && scrollTop >= hotTop) {
          this.navgatorIndex = -1;
        }
        //作用是点击导航栏时，延迟这里执行。
        this.citysList.map((v, i) => {
          // 获取监听元素距离视窗顶部距离
          var offsetTop = document.getElementById(`id${i}`).offsetTop;
          // 获取监听元素本身高度
          var scrollHeight = document.getElementById(`id${i}`).scrollHeight;
          // 如果 dom滚动位置 >= 元素距离视窗距离 && dom滚动位置 <= 元素距离视窗距离+元素本身高度
          // 则表示页面已经滚动到可视区了。
          if (scrollTop >= offsetTop && scrollTop <= offsetTop + scrollHeight) {
            // 导航栏背景色选中
            this.navgatorIndex = i;
          }
        });
      },
      // 滚动选中
      topScrollToTop() {
        let scrollTop = this.$refs.topcitys.scrollTop;

        // 热门选中
        var hotTop = document.getElementById(`ids-1`).offsetTop;
        var hotHeight = document.getElementById(`ids0`).scrollHeight;
        if (scrollTop <= hotHeight && scrollTop >= hotTop) {
          this.navgatorIndex = -1;
        }
        //作用是点击导航栏时，延迟这里执行。
        this.citysList.map((v, i) => {
          // 获取监听元素距离视窗顶部距离
          var offsetTop = document.getElementById(`ids${i}`).offsetTop;
          // 获取监听元素本身高度
          var scrollHeight = document.getElementById(`ids${i}`).scrollHeight;
          // 如果 dom滚动位置 >= 元素距离视窗距离 && dom滚动位置 <= 元素距离视窗距离+元素本身高度
          // 则表示页面已经滚动到可视区了。
          if (scrollTop >= offsetTop && scrollTop <= offsetTop + scrollHeight) {
            // 导航栏背景色选中
            this.navgatorIndex = i;
          }
        });
      },
      // 选中城市
      handleSelect(id, name) {
        this.form.city_id = id;
        this.form.city_name = name;
        this.showCity = false;
        this.topShowCity = false;
        // 保存vuex
        this.$store.commit('SET_FORM_CITY', {city_id: id, city_name: name});
      },
      tochoose() {
        this.$router.push({path: '/about'})
      },
      // 倒计时
      countdowm() {
        let phone = this.form.phone
        const reg = /^[1][0-9]{10}$/;
        if (reg.test(phone)) {
          captche({phone: phone}).then(res => {
            let totalTime = 60;
            if (!this.canClick) return; //节流
            this.canClick = false;
            this.content = totalTime + "s";
            this.refreshData = window.setInterval(() => {
              totalTime--;
              this.content = totalTime + "s";
              if (totalTime < 0) {
                //当倒计时小于0时清除定时器
                window.clearInterval(this.refreshData); //清除定时器
                this.content = "发送验证码";
                totalTime = 60;
                this.canClick = true; //这里重新开启
              }
            }, 1000);
          }).catch(err => {
            this.$message.error(err);
          })
        } else {
          this.$message.error('请输入正确的手机号');
        }
      },
      // 表单验证
      handleSubmit(formName) {
        if (this.istrue == false) {
          this.$message.error('请先阅读用户隐私保护协议');
          return false;
        } else {
          this.$refs.form.validate((valida) => {
            if (valida) {
              // 获取价格
              let cate_id = this.$store.getters.carInfo.cate_id;
              carPrice({demio_id: cate_id}).then(ret => {
                this.price = ret.data.price
                if (parseFloat(ret.data.price) != 0) {
                  // 选择支付方式
                  this.centerDialogVisible = true;
                  this.handlePayType()
                } else {
                  this.handlePayType(1)
                }
              }).catch(r => {
                this.centerDialogVisible = false;
                this.$message.error('请选择车型');
              })
            } else {
              this.centerDialogVisible = false;
              this.$message.error('请完善信息');
              return false;
            }
          })
        }
      },
      // 下单支付
      handlePayType(e = '') {
        clearInterval(this.refreshOrder) //清除定时器
        this.form.type = e;
        let form = this.form;
        if (form.type != '') {
          let store = JSON.parse(localStorage.getItem('form'));
          let newArr = {
            name: form.name,
            phone: form.phone,
            code: form.code,
            brand: store.brand,
            city_id: store.city_id,
            pay_type: form.pay_type,
            car_series: store.car_series, // 车型id
            car_type: store.car_type, // 车系id
            is_scan: form.is_scan,
            type: form.type // 支付方式1微信支付其他支付宝
          }
          orderAdd(newArr).then(ret => {
            // 客服二维码
            customer({order_id: ret.data.order_id}).then(cs => {
              this.customerImg = cs.data.qrcode
            }).catch(err => {
              this.$message.error(err);
            })
            this.orderId = ret.data.order_id;
            if (parseFloat(this.price) == 0) {
              // 支付成功
              this.succesVisible = true;
            } else {
              if (e == 1) {
                // 生成支付二维码
                this.getQRcode(ret.data.pay.code_url);
              } else {
                window.open(ret.data.pay, "_blank");
              }
              // 实时查询订单
              this.getOrderStatus();
            }
          }).catch(err => {
            this.$message.error(err);
          })
        }
      },
      // 获取支付二维码
      getQRcode(url){
        QRCode.toDataURL(url, { errorCorrectionLevel: 'L', margin: 2, width: 128 }, (err, url) => {
          if (err) this.$message.error(err);
          this.qrImgUrl = url
        })
      },
      // 获取订单支付状态
      getOrderStatus() {
        this.refreshOrder = setInterval(() => {
          let orderId = this.orderId;
          orderInfo({order_id: orderId}).then(res => {
            if (res.data == 1) {
              // 清空表单数据
              this.$refs.form.resetFields();
              clearInterval(this.refreshOrder) //清除定时器
              // 支付方式
              this.centerDialogVisible = false;
              // 支付成功
              this.succesVisible = true;
            }
          }).catch(err => {})
        }, 1000);
      },
      // 关闭支付弹框 清除定时器
      closepops() {
        clearInterval(this.refreshOrder); //清除定时器
        this.centerDialogVisible = false;
        this.qrImgUrl = '';
      },
      // 用户协议
      handleAggre() {
        this.aggreDialogVisible = true;
      },
      // 勾选协议
      handleReady(e) {
        this.istrue = e;
      },
      // 关闭成功的弹框
      confirm() {
        // 清空表单数据
        this.$refs.form.resetFields();
        this.succesVisible = false;
      },
      onactive(e) {
        this.$router.push({
          name: "BrandDetail",
          query: {
            id: e.id,
            type: e.type
          }
        });
      },
      // 关闭弹窗
      closepop() {
        // 清空表单数据
        this.$refs.form.resetFields();
        this.succesVisible = false;
      },
    }
  };
</script>
<style lang="scss" scoped>
  ::v-deep .el-radio {
    margin: 0;
  }
  .el-form-item {
    margin: 0 !important;
  }

  .price-box {
    text-align: center;

    .money {
      color: #EB1B34;
    }

    .box-type {
      height: 30px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-size: 16px;
    }
  }

  .home {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .citys {
    width: 560px;
    height: 398px;
    background-color: #fff;
    display: flex;

    .citys-left {
      width: 162px;
      display: flex;
      flex-flow: column;
      flex-wrap: wrap;
      align-items: center;
      border-right: 1px solid rgba(220,222,224,1);
      cursor: pointer;

      .citys-left-name {
        width: 26px;
        height: 26px;
        text-align: center;
        margin: 8px;
      }

      .citys-left-checked {
        width: 26px;
        height: 26px;
        line-height: 26px;
        border-radius: 15px;
        opacity: 1;
        background: rgba(36,113,228,1);
        color: #fff;
      }
    }

    .citys-right {
      width: calc(100% - 162px);

      .citys-right-box {
        height: 100%;
        overflow: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }

        .citys-right-title {
          width: calc(100% - 24px);
          height: 38px;
          line-height: 38px;
          padding-left: 24px;
          background-color: #F7F8FA;
        }

        .citys-right-name {
          display: flex;
          flex-wrap: wrap;
          cursor: pointer;

          .citys-right-city {
            height: 38px;
            line-height: 38px;
            padding: 0 24px;
          }

          .citys-right-city-checked {
            color: #2471E4;
          }
        }
      }
    }
  }

  .bgimg {
    background-image: url("@/assets/bg.png");
    background-size: 100% 100%;
    width: 100%;
    padding-top: 80px;
    height: 872px;

    .bgcenter {
      margin: 0 auto;
      padding-top: 40px;
      width: 1000px;
      height: 712px;
      border-radius: 12px;
      opacity: 1;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0 8px 24px 0 rgba(100, 101, 102, 0.12);

      .cartop {
        margin: 0 auto;
        width: 560px;
        height: 64px;
        border-radius: 12px;
        opacity: 1;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0 8px 24px 0 rgba(100, 101, 102, 0.12);

        .carleft {
          justify-content: center;
          width: 166px;
          height: 64px;
          line-height: 64px;
          border-radius: undefineNaNpxx undefineNaNpxx undefineNaNpxx undefineNaNpxx;
          opacity: 1;
          background: linear-gradient(90deg,
          rgba(227, 241, 255, 1) 0%,
          rgba(180, 208, 250, 1) 100%);
          color: rgba(41, 49, 61, 1);
          font-size: 20px;
          font-weight: 600;
          cursor: pointer;
        }

        .carright {
          padding-left: 40px;
          flex: 1;
          line-height: 64px;
          height: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          .carright-box {
            width: 80%;
            display: flex;
            justify-content: space-around;
          }

          .carright-car {
            color: #2471E4;
          }
        }
      }

      .carter {
        margin: 0 auto;
        margin-top: 24px;
        width: 560px;

        .terall {
          flex: 1;
          padding-left: 24px;
          color: rgba(41, 49, 61, 1);
          font-size: 20px;
          font-weight: 600;
          font-family: "PingFang SC";
        }

        .catchoose {
          cursor: pointer;
          padding: 8px 0;
          writing-mode: vertical-rl;
          letter-spacing: 6px; //设置间距
          width: 32px;
          height: 116px;
          border-radius: 4px;
          opacity: 1;
          background: rgba(235, 237, 240, 1);
        }
      }

      .formter {
        width: 560px;
        margin: 0 auto;

        .formbar {
          margin-top: 16px;
          position: relative;
          top: 0;
          left: 0;

          .name {
            width: 80px;
            position: absolute;
            top: 50%;
            left: -104px;
            transform: translate(0, -50%);
            text-align: right;
          }

          .code {
            position: absolute;
            top: 50%;
            right: 32px;
            cursor: pointer;
            transform: translate(0, -50%);
            color: rgba(36, 113, 228, 1);
            font-size: 14px;
            font-weight: 400;
            font-family: "PingFang SC";
          }
        }
      }

      .submit {
        cursor: pointer;
        width: 560px;
        height: 52px;
        margin: 0 auto;
        opacity: 1;
        margin-top: 40px;
        border: 0 solid rgba(0, 117, 249, 1);
        background: rgba(36, 113, 228, 1);
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
        font-weight: 600;
        line-height: 52px;
        font-family: "PingFang SC";
        text-align: center;
        animation: throttle 3s step-end forwards;
      }
      // 节流
      .submit:active {
        animation: none;
      }

      @keyframes throttle {
        from {
          pointer-events: none;
        }
        to {
          pointer-events: all;
        }
      }

      .tip {
        width: 560px;
        margin: 0 auto;
        margin-top: 24px;
        text-align: center;
        color: rgba(150, 151, 153, 1);
        font-size: 14px;
        font-weight: 400;

        .tip1 {
          color: rgba(41, 49, 61, 1);
          font-size: 14px;
          cursor: pointer;
        }
      }
    }
  }

  .last {
    width: 100%;
    padding: 80px 0;
    background-color: #eeeff4;

    .last1 {
      margin: 0 auto;
      width: 1200px;
      border-radius: 12px;
      opacity: 1;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0 4px 24px 0 rgba(100, 101, 102, 0.12);
      padding: 64px;
      box-sizing: border-box;
      img{
        width: 1000px;
      }
      .txt {
        margin-left: 80px;

      }
    }

    .lastbot {
      margin: 0 auto;
      margin-top: 40px;
      width: 1200px;

      .last2 {
        width: 580px;
        height: 167px;
        border-radius: 12px;
        opacity: 1;
        padding: 24px;
        box-sizing: border-box;
        background: rgba(255, 255, 255, 1);
        background-image: url("@/assets/99.png");
        background-size: 130px 130px;
        background-position: top right;
        background-repeat: no-repeat;

        .txt2 {
          color: #29313d;
          font-size: 24px;
          font-weight: 700;
          font-family: "PingFang SC";
        }

        .txt3 {
          margin-top: 8px;
          color: rgba(150, 151, 153, 1);
          font-size: 12px;
          font-weight: 400;
          font-family: "PingFang SC";
        }

        .txt4 {
          margin-top: 17px;
          color: rgba(41, 49, 61, 1);
          font-size: 16px;
          font-weight: 400;
          font-family: "PingFang SC";
        }
      }

      .last3 {
        background: rgba(255, 255, 255, 1);
        background-image: url("@/assets/98.png");
        background-size: 130px 130px;
        background-position: top right;
        background-repeat: no-repeat;
      }
    }
  }

  .contail {
    margin: 0 auto;
  }

  .allpop {
    position: relative;
    top: 0;
    left: 0;

    .succpop {
      box-sizing: border-box;
      width: 400px;
      opacity: 1;
      text-align: center;
      background: rgba(255, 255, 255, 1);

      .succ1 {
        margin-top: 24px;
        color: rgba(41, 49, 61, 1);
        font-size: 18px;
        font-weight: 600;
        font-family: "PingFang SC";
      }

      .succ2 {
        margin-top: 32px;
        color: rgba(36, 113, 228, 1);
        font-size: 26px;
        font-weight: 600;
        font-family: "PingFang SC";
        text-align: center;
      }

      .succ3 {
        margin-top: 12px;
        color: rgba(150, 151, 153, 1);
        font-size: 16px;
        font-weight: 400;
        font-family: "PingFang SC";
      }

      .succ4 {
        margin: 0 auto;
        margin-top: 32px;
        width: 160px;
        height: 44px;
        line-height: 44px;
        opacity: 1;
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
        font-weight: 600;
        font-family: "PingFang SC";
        text-align: center;
        border: 0 solid rgba(0, 117, 249, 1);
        background: rgba(36, 113, 228, 1);
        cursor: pointer;
      }

      .customer {
        margin-top: 10px;

        img {
          width: 160px;
          height: 160px;
        }
      }
    }

    .succimg {
      margin-top: 52px;
      width: 400px;
      position: absolute;

      img {
        width: 100%;
        height: 100%;
      }

      .succbtn {
        width: 160px;
        height: 44px;
        opacity: 1;
        position: absolute;
        left: 50%;
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
        font-weight: 500;
        font-family: "PingFang SC";
        text-align: center;
        line-height: 44px;
        transform: translate(-50%, -50%);
        border: 0 solid rgba(0, 117, 249, 1);
        background: rgba(36, 113, 228, 1);
        cursor: pointer;
        margin-top: 30px;
      }

      .succclose {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
        margin-top: 90px;
      }
    }

  }
</style>
