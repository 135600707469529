<template>
  <div class="about">
    <Topnev :checked="'首页'"></Topnev>
    <div class="contain">
      <div class="cartop flexbetton">
        <div class="carleft">{{brandName}}</div>
        <div class="carright">
          <div class="rightbox flexalign">
            <img class="rightimg" src="@/assets/Group 583.png" alt="" />
            <el-carousel height="64px" direction="vertical" class="lun">
              <el-carousel-item v-for="(item, i) in enquiryList" :key="i">
                <div class="carright-box"><span>{{item.name}}</span> <span>{{item.createtime}}前成功询价</span> <span
                    class="carright-car">{{item.car_type}}</span></div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
      <!-- 背景图 -->
      <div class="carimg">
        <el-carousel height="360px">
          <el-carousel-item v-for="(item, index) in bannerList" :key="index">
            <img :src="item.image" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- 车辆管理 -->
      <!-- 一致性 Consistency<i class="header-icon el-icon-info"></i> -->
      <div class="carbox">
        <el-collapse accordion v-for="(it, id) in carList" :key="id">
          <el-collapse-item>
            <template slot="title">
              <div class="boxtop flexalign">
                <img :src="it.img" alt="" />
                <div class="boxtitle">{{it.name}}</div>
                <div class="boxmoney flexjust">
                  {{it.qv}}<img src="@/assets/Group 5.png" alt="" />
                </div>
                <div class="boxlast">展开所有车型</div>
              </div>
            </template>
            <div class="boxcant">
              <div class="topbox">
                <div class="canttop">
                  <div class="it1">车辆列表</div>
                  <div class="it2">官方指导价</div>
                  <div class="it3">价格波动</div>
                </div>
                <div class="itemtop" v-for="(t, i) in it.car_list" :key="i">
                  <div class="it1">{{t.name}}</div>
                  <div class="it2">{{t.suggest}}万</div>
                  <div class="it3 flexalign">较上周价格
                    <img v-if="t.sign == 'down'" src="@/assets/Iconly-Light-Arrow - Down.png" alt=""/>
                    <img v-if="t.sign == 'up'" src="@/assets/Iconly-Light-Arrow - Up.png" alt=""/>
                  </div>
                  <div class="it4 flexalign">
                    <div class="btn1" @click="handlePrice(t.id, it.id, t, it.img)">去询价</div>
                    <div class="btn2" @click="ondetail(t.id, it.id)">查看详情</div>
                  </div>
                </div>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
  </div>
</template>
<script>
import { pinAz, brandBanner, homeEnquiry, carList } from "@/untils/api";
export default {
  name: "ChooseCar",
  data() {
    return {
      bannerList: [],
      enquiryList: [],
      carList: [],
      brandName: '',
    };
  },
  created() {
    // 询价
    homeEnquiry().then(ret => {
      this.enquiryList = ret.data
    }).catch(err => {
      this.$message.error(err);
    })
  },
  mounted() {
    let store = JSON.parse(localStorage.getItem('form'));
    this.brandName = store.brand_name
    // 轮播图
    brandBanner({p_id: store.brand}).then(res => {
      this.bannerList = res.data
    }).catch(err => {
      this.$message.error(err);
    })
    // 车辆列表
    carList({city_id: store.city_id, brand_id: store.brand}).then(ret => {
      ret.data.forEach((element) => {
        element.car_cate2.forEach((e2) => {
          e2.car_list.forEach((i) => {
            if (parseFloat(i.suggest) > parseFloat(i.last_week_price)) {
              i.sign = 'up'
            } else {
              i.sign = 'down'
            }
          })
          this.carList.push(e2);
        });
      });
    }).catch(err => {
      this.$message.error(err);
    })
  },
  methods: {
    // 询价
    handlePrice(type_id, series_id, info, image) {
      this.$store.commit('SET_FORM_TYPE',{type_id: type_id, series_id: series_id});
      info.image = image;
      this.$store.commit('SET_CAR_INFO', info);
      this.$router.push({path: '/'})
    },
    ondetail(type_id, series_id) {
      this.$store.commit('SET_FORM_TYPE',{type_id: type_id, series_id: series_id});
      this.$router.push({name: "CarDetail", query: {carId: type_id}});
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-collapse-item__header {
  height: 120px;
  border-radius: 4px;
}

::v-deep .el-collapse-item__arrow {
  position: relative;
  left: -20px;
}

.contain {
  margin: 0 auto;
  margin-top: 40px;
  width: 1200px;
  background: #ffffff;
  .cartop {
    .carright {
      line-height: 64px;
      height: 100%;
      .rightbox {
        width: 340px;
        padding: 0 4px;
        height: 44px;
        border-radius: 4px;
        opacity: 1;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0 8px 24px 0 rgba(100, 101, 102, 0.12);
        .rightimg {
          margin-right: 28px;
          width: 36px;
          height: 36px;
        }
        .lun {
          width: 300px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .carright-box {
          width: 100%;
          display: flex;
          justify-content: space-around;
        }

        .carright-car {
          color: #2471E4;
        }
      }
    }
  }
  .carimg {
    margin-top: 18px;
    height: 360px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .carbox {
    margin-top: 40px;
    .boxtop {
      width: 100%;
      height: 120px;
      position: relative;
      img {
        width: 120px;
        height: 120px;
      }
      .boxtitle {
        width: 300px;
        padding-left: 40px;
        color: rgba(41, 49, 61, 1);
        font-size: 26px;
        font-weight: 500;
        font-family: "PingFang SC";
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .boxmoney {
        width: 500px;
        color: rgba(235, 27, 52, 1);
        font-size: 26px;
        font-weight: 400;
        font-family: "PingFang SC";
        img {
          margin-left: 12px;
          width: 86px;
          height: 24px;
        }
      }
      .boxlast {
        position: absolute;
        right: 0;
        width: 142px;
        height: 40px;
        line-height: 40px;
        color: rgba(100, 101, 102, 1);
        font-size: 14px;
        font-weight: 400;
        font-family: "PingFang SC";
        text-align: center;
        border-radius: 4px;
        opacity: 1;
        background: rgba(242, 243, 245, 1);
      }
    }
    .boxcant {
      opacity: 1;
      padding: 0 24px;
      background: rgba(247, 248, 250, 1);
      padding-bottom: 8px;
      .topbox {
        .canttop {
          color: rgba(150, 151, 153, 1);
          font-size: 16px;
          font-weight: 400;
          font-family: "PingFang SC";
          height: 56px;
          line-height: 56px;
          display: grid;
          grid-template-columns: 363px 228px 281px 280px;
          .it1 {
            padding-left: 24px;
          }
        }
        .itemtop {
          height: 74px;
          opacity: 1;
          background: rgba(255, 255, 255, 1);
          display: grid;
          font-size: 18px;
          font-weight: 400;
          font-family: "PingFang SC";
          line-height: 74px;
          grid-template-columns: 363px 228px 281px 280px;
          margin: 16px 0;
          .it1 {
            padding-left: 24px;
          }
          .it3 {
            img {
              margin-left: 16px;
              width: 24px;
              height: 24px;
            }
          }
          .it4 {
            .btn1 {
              margin-right: 16px;
              width: 120px;
              height: 44px;
              line-height: 44px;
              opacity: 1;
              border: 0 solid rgba(0, 117, 249, 1);
              background: rgba(36, 113, 228, 1);
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              font-weight: 600;
              font-family: "PingFang SC";
              text-align: center;
              cursor: pointer;
            }
            .btn2 {
              width: 120px;
              height: 44px;
              line-height: 44px;
              opacity: 1;
              color: rgba(36, 113, 228, 1);
              font-size: 16px;
              font-weight: 600;
              font-family: "PingFang SC";
              text-align: center;
              border: 0 solid rgba(0, 117, 249, 1);
              background: rgba(227, 241, 255, 1);
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>
