import request from '@/untils/request'
// 标题分类,homeHeadCategory为接口名
export const pinAz = (data) => {
  return request('/api/index/pinAz', 'get', data)
}
// 首页轮播图
export const homeBanner = (data) => {
  return request('/h5/banner/getBanner', 'get', data)
}
// 询价记录
export const homeEnquiry = (data) => {
  return request('/h5/Enquiry/enquiry_list', 'get', data)
}
// 城市信息
export const citys = (data) => {
  return request('/api/citys/allCity', 'get', data)
}
// 热门城市
export const hotCitys = (data) => {
  return request('/api/citys/hotCity', 'get', data)
}
// 发送验证码
export const captche = (data) => {
  return request('/h5/enquiry/send_code', 'get', data)
}
// 询价
export const orderAdd = (data) => {
  return request('/h5/enquiry/add', 'post', data)
}
// 详情支付
export const orderPay = (data) => {
  return request('/h5/enquiry/detail_pay', 'post', data)
}
// 获取订单状态
export const orderInfo = (data) => {
  return request('/api/p_c_index/enquiryOrderStatus', 'get', data)
}
// 活动、成功案例轮播图
export const banner = (data) => {
  return request('/api/p_c_index/bannerList', 'get', data)
}
// 活动列表
export const active = (data) => {
  return request('/api/p_c_index/pageActive', 'get', data)
}
// 活动详情
export const activeInfo = (data) => {
  return request('/api/active/detail4s', 'get', data)
}
// 活动参与人
export const activePeople = (data) => {
  return request('/api/active/applyActiveUserList', 'get', data)
}
// 品牌列表
export const brand = (data) => {
  return request('/api/p_c_index/brandList', 'get', data)
}
// 成功案例
export const successful = (data) => {
  return request('/api/p_c_index/successCase', 'get', data)
}
// 成功案例详情
export const successInfo = (data) => {
  return request('/api/p_c_index/getSuccessCase', 'get', data)
}
// 车市新闻
export const carNew = (data) => {
  return request('/api/p_c_index/news', 'get', data)
}
// 品牌轮播图
export const brandBanner = (data) => {
  return request('/h5/banner/getBrandBanner', 'get', data)
}
// 车辆列表
export const carList = (data) => {
  return request('/h5/car/getCarList', 'get', data)
}
// 车辆详情
export const carInfo = (data) => {
  return request('h5/car/getCarInfo', 'get', data)
}
// 询价价格
export const carPrice = (data) => {
  return request('/h5/Enquiry/getPrice', 'post', data)
}
// 人才招聘列表
export const talentsPage = (data) => {
  return request('/api/p_c_index/talentsPage', 'get', data)
}

// 人才招聘详情
export const getTalents = (data) => {
  return request('/api/p_c_index/getTalents', 'get', data)
}

// 富文本内容接口
export const getText = (data) => {
  return request('/api/p_c_index/getText', 'get', data)
}

// 车市新闻详情接口
export const carNewsDetail = (data) => {
  return request('/api/shop4s2/newsDetail', 'get', data)
}
// 合作企业
export const teamwork = (data) => {
  return request('/api/p_c_index/TeamworkFirm', 'get', data)
}
// 活动
export const carActive = (data) => {
  return request('/api/p_c_index/enquiryAchieve', 'get', data)
}
// 客服二维码
export const customer = (data) => {
  return request('/h5/banner/getQrcode', 'get', data)
}
