import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    form: {
      city_id: 871,
      city_name: '南通市',
      brand: '',
      brand_name: '',
      car_type: '',
      car_series: ''
    },
    carInfo: {}
  },
  getters: {
    carInfo: state => {
      return state.carInfo
    }
  },
  mutations: {
    SET_FORM_CITY: (state, val) => {
      if (localStorage.getItem('form')) {
        state.form = JSON.parse(localStorage.getItem('form'));
      }
      state.form.city_id = val.city_id
      state.form.city_name = val.city_name
      localStorage.setItem('form', JSON.stringify(state.form));
    },
    SET_FORM_BRAND: (state, val) => {
      if (localStorage.getItem('form')) {
        state.form = JSON.parse(localStorage.getItem('form'));
      }
      state.form.brand = val.brand_id
      state.form.brand_name = val.brand_name
      localStorage.setItem('form', JSON.stringify(state.form));
    },
    SET_FORM_TYPE: (state, val) => {
      if (localStorage.getItem('form')) {
        state.form = JSON.parse(localStorage.getItem('form'));
      }
      state.form.car_type = val.type_id
      state.form.car_series = val.series_id
      localStorage.setItem('form', JSON.stringify(state.form));
    },
    SET_CAR_INFO: (state, val) => {
      state.carInfo = val
      localStorage.setItem('carInfo', JSON.stringify(val));
    }
  },
  actions: {
  },
  modules: {
  }
})
