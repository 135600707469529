<template>
  <!-- 头部组件 -->
  <div class="botmnev">
    <div class="narbar">
      <div class="container">
        <div class="nev1 flexalign">
          <div class="baritem flexcolum" v-for="(item, index) in nevlist" :key="index">
            <router-link class="itembar" :to="item.to" tag="a">{{
              item.name
            }}</router-link>
          </div>
        </div>
        <div class="nev2">
          <div class="nev2-box" v-for="(e, i) in teamwork" :key="i" @click="jumpUrl(e.link_url)">
            <img :src="e.logo_image" alt="">
            <div class="baritem">{{e.name}}</div>
          </div>
        </div>
      </div>
      <div class="fot" v-html="serviceCopyright" @click="gowailian"></div>
    </div>
  </div>
</template>
<script>
  import {
    teamwork,
    getText
  } from "@/untils/api";
  export default {
    name: "Botnev",
    data() {
      return {
        nevlist: [
          {name: "首页", to: "/"},
          {name: "车市新闻", to: "/CarNews"},
          {name: "品牌活动", to: "/BrandView"},
          {name: "成功案例", to: "/Successful"},
          {name: "关于我们", to: "/AboutUs"},
          {name: "人才招聘", to: "/Recruit"},
        ],
        teamwork: [],
        serviceCopyright: ''
      };
    },
    created() {
      // 合作伙伴
      teamwork().then(tem => {
        this.teamwork = tem.data
      }).catch(err => {
        this.$message.error(err);
      })
      // 版权
      getText({id: 13}).then(copy => {
        this.serviceCopyright = copy.data.content
      }).catch(err => {
        this.$message.error(err);
      })
    },
    methods: {
      gowailian(){
         window.open('https://beian.miit.gov.cn/', "_blank");
      },
      jumpUrl(url) {
        window.location.href = url
      }
    }
  };
</script>
<style lang="scss" scoped>
  .botmnev {

    .narbar {
      position: relative;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 201px;
      opacity: 1;
      background: rgba(41, 49, 61, 1);

      .container {
        margin: 0 auto;
        height: 100%;

        .nev1 {
          justify-content: center;
          border-bottom: 1px solid #424040;

          .baritem {
            width: 148px;
            height: 72px;
            line-height: 72px;
            text-align: center;
            color: rgba(255, 255, 255, 1);
            font-size: 20px;
            font-weight: 400;
            font-family: "PingFang SC";
            opacity: 1;
            background: rgba(217, 217, 217, 0);
          }
        }

        .nev2 {
          width: calc(100% - 240px);
          height: 88px;
          margin: auto;
          display: flex;
          flex-flow: wrap;
          overflow: auto;
          -ms-overflow-style: none;
          scrollbar-width: none;
          &::-webkit-scrollbar {
            display: none;
          }

          .nev2-box {
            display: flex;
            flex-shrink: 0;
            margin: 10px;
            background: rgba(255, 255, 255, 0.08);
            padding: 8px 16px;
            border-radius: 4px;
            cursor: pointer;

            >img {
              width: 24px;
              height: 24px;
            }

            .baritem {
              margin-left: 16px;
              border-radius: 4px;
              opacity: 1;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              opacity: 1;
            }
          }
        }
      }

      .fot {
        cursor: pointer;
        position: absolute;
        bottom: 0;
        left: 0;
        text-align: center;
        width: 100%;
        height: 40px;
        line-height: 40px;
        opacity: 1;
        background: rgba(50, 59, 72, 1);
        color: rgba(255, 255, 255, 1);
        font-size: 12px;
        font-weight: 400;
        font-family: "PingFang SC";
      }
    }
  }
</style>
