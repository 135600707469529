// 请求封装
import axios from 'axios'
// 域名
export const instance = axios.create({
  // baseURL: 'http://test.xiaoxiangauto.com',
  baseURL: 'https://xiaoxiangauto.com',
})

export const baseAurl = 'https://xiaoxiangauto.com' + '/api/common/upload'
export const bgbaseAurl = 'https://xiaoxiangauto.com'

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
  const {
    code,
    msg,
    data
  } = response.data
  if (code == 1) {
    return Promise.resolve(response.data)
  } else {
    return Promise.reject(msg) // 返回是一个只会失败的promise 到catch
  }
}, error => {
  if (error.response.data.code == '401') {
    this.$message.error({
      message: "请重新登录",
      type: "none",
    });
    localStorage.removeItem("token")
    window.location.href = '/login'
  }
  return Promise.reject(error) // 返回执行错误 让当前的执行链跳出成功 直接进入 catch
})

// 请求工具函数
export default (url, method, submitData) => {
  // 负责发请求：请求地址，请求方式，提交的数据
  return instance({
    url,
    method,
    [method.toLowerCase() === 'get' ? 'params' : 'data']: submitData,
    // 自定义请求头
    headers: {
      "Content-Type":"multipart/form-data"
    },
  })
}
