<template>
  <!-- 头部组件 -->
  <div class="topnev">
    <div class="narbar">
      <div class="container flexbetton">
        <div class="logo">
          <img src="@/assets/imglogo.png" alt="" />
        </div>
        <div class="baritem flexcolum" :class="[item.name == checked ? 'bottom-checked' : 'bottom']"
          v-for="(item, index) in nevlist" :key="index">
          <router-link style="user-select:none" class="itembar" :to="item.to" tag="a">{{
            item.name
          }}</router-link>
        </div>
        <div class="phone flexcolum">
          <div class="itembar">
            <div to="CarDetail" class="ph1">联系我们</div>
            <div class="ph2" v-html="contact"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {
    getText
  } from "@/untils/api";
  export default {
    name: "Topnev",
    props: {
      checked: ''
    },
    data() {
      return {
        nevlist: [
          {name: "首页", to: "/"},
          {name: "车市新闻", to: "/CarNews"},
          {name: "品牌活动", to: "/BrandView"},
          {name: "成功案例", to: "/Successful"},
          {name: "关于我们", to: "/AboutUs"},
        ],
        contact: ''
      }
    },
    created() {
      // 联系电话
      getText({id: 12}).then(tmp => {
        this.contact = tmp.data.content
      }).catch(err => {
        this.$message.error(err);
      })
    }
  }
</script>
<style lang="scss" scoped>
  .topnev {
    .narbar {
      width: 100%;
      height: 80px;
      opacity: 1;
      background: rgba(41, 49, 61, 1);

      .container {
        margin: 0 auto;
        height: 100%;

        .baritem {
          width: 148px;
          height: calc(100% - 4px);
          text-align: center;
          color: rgba(255, 255, 255, 1);
          font-size: 20px;
          font-weight: 400;
          font-family: "PingFang SC";
          opacity: 1;
          background: rgba(217, 217, 217, 0);
        }

        .phone {
          cursor: pointer;
          width: 190px;
          height: 56px;
          border-radius: 28px;
          opacity: 1;
          text-align: center;
          background: linear-gradient(-54deg,
              rgba(36, 113, 228, 1) 0%,
              rgba(52, 160, 239, 1) 100%);

          .ph1 {
            color: rgba(255, 255, 255, 1);
            font-size: 14px;
            font-weight: 400;
            font-family: "PingFang SC";
          }

          .ph2 {
            color: rgba(255, 255, 255, 1);
            font-size: 20px;
            font-weight: 700;
            font-family: "PingFang SC";
          }
        }

        .bottom {
          width: 80px;
          border-bottom: 4px solid #29313D;
        }

        .bottom-checked {
          width: 80px;
          border-bottom: 4px solid #FFFFFF;
        }
      }
    }
  }
</style>
