<template>
  <div class="about">
    <Topnev :checked="'首页'"></Topnev>
    <!-- 轮播图 -->
    <div class="banner">
      <el-carousel height="480px" class="banneritem">
        <el-carousel-item v-for="item in info.images" :key="item">
          <img :src="item" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 主体 -->
    <div class="contian">
      <div class="dettop">
        <div class="dettitle">{{info.name}}</div>
        <div class="detalign flexbetton">
          <div class="boxmoney flexjust">
            {{info.suggest}} 万<img src="@/assets/Group 5.png" alt="" />
          </div>
          <div class="it3 flexalign">较上周价格
            <img v-if="info.sign == 'down'" src="@/assets/Iconly-Light-Arrow - Down.png" alt="" />
            <img v-else src="@/assets/Iconly-Light-Arrow - Up.png" alt="" />
          </div>
          <div class="btn1" @click="showpop">去询价</div>
        </div>
      </div>
      <div class="detlist">
        <div class="detbar flexalign">
          <div class="detleft">指导价</div>
          <div class="detright">{{info.suggest}}万元</div>
        </div>
        <div class="detbar huibg flexalign">
          <div class="detleft">发动机</div>
          <div class="detright">{{info.engine}}</div>
        </div>
        <div class="detbar flexalign">
          <div class="detleft">长*宽*高（mm）</div>
          <div class="detright">{{info.size}}</div>
        </div>
        <div class="detbar huibg flexalign">
          <div class="detleft">轴距（mm）</div>
          <div class="detright">{{info.torque}}</div>
        </div>
        <div class="detbar flexalign">
          <div class="detleft">综合油耗（L）</div>
          <div class="detright">{{info.fuel}}</div>
        </div>
      </div>
      <!-- 底图 -->
      <!-- <div class="dibg">
        <img src="@/assets/banner.png" alt="" />
      </div> -->
    </div>
    <!-- 支付弹窗 -->
    <div>
      <el-dialog :visible.sync="dialogVisible" width="600px" :show-close="false">
        <div class="pop">
          <el-carousel height="240px" class="popitem">
            <el-carousel-item v-for="item in info.images" :key="item">
              <img :src="item" alt="" />
            </el-carousel-item>
          </el-carousel>
          <div class="popdetlist">
            <div class="popdetbar flexalign">
              <div class="popdetleft">指导价</div>
              <div class="popdetright">{{info.suggest}}万元</div>
            </div>
            <div class="popdetbar huibg flexalign">
              <div class="popdetleft">发动机</div>
              <div class="popdetright">{{info.engine}}</div>
            </div>
            <div class="popdetbar flexalign">
              <div class="popdetleft">长*宽*高（mm）</div>
              <div class="popdetright">{{info.size}}</div>
            </div>
            <div class="popdetbar huibg flexalign">
              <div class="popdetleft">轴距（mm）</div>
              <div class="popdetright">{{info.torque}}</div>
            </div>
            <div class="popdetbar flexalign">
              <div class="popdetleft">综合油耗（L）</div>
              <div class="popdetright">{{info.fuel}}</div>
            </div>
          </div>
          <div class="pdettop">
            <div class="pdettitle">{{info.name}}</div>
            <div class="pdetalign flexbetton">
              <div class="pboxmoney flexjust">
                {{info.suggest}}万<img src="@/assets/Group 5.png" alt="" />
              </div>
              <div class="pit3 flexalign">较上周价格
                <img v-if="info.sign == 'down'" src="@/assets/Iconly-Light-Arrow - Down.png" alt="" />
                <img v-else src="@/assets/Iconly-Light-Arrow - Up.png" alt="" />
              </div>
            </div>
          </div>
          <div class="paycs">
            <el-form :model="form" :rules="rules" ref="form">
              <div class="formbar flexalign">
                <div class="name">姓名</div>
                <el-form-item prop="name">
                  <el-input class="put" placeholder="请输入姓名" v-model="form.name" clearable>
                  </el-input>
                </el-form-item>
              </div>

              <div class="formbar flexalign">
                <div class="name">电话</div>
                <el-form-item prop="phone">
                  <el-input class="put" placeholder="请输入电话" v-model="form.phone" clearable>
                  </el-input>
                </el-form-item>
              </div>

              <div class="formbar flexalign">
                <div class="name">验证码</div>
                <el-form-item prop="code">
                  <el-input class="put" placeholder="请输入验证码" v-model="form.code" clearable>
                  </el-input>
                </el-form-item>
                <div class="code" v-if="canClick == true" @click="countdowm" v-text="content "></div>
                <div class="code" v-else v-text="content "></div>
              </div>

              <el-form-item>
                <div class="paybtn flexbetton">
                  <div class="flexalign">
                    实付款:<p class="moneybtn">¥{{price}}</p>
                    已优惠0元
                  </div>
                  <div class="pay" @click="payok('form')">{{btnTxt}}</div>
                </div>
              </el-form-item>
            </el-form>
          </div>
          <div class="close" @click="closepop">
            <img src="@/assets/ic-clear.png" alt="" />
          </div>
        </div>
      </el-dialog>
    </div>
    <div>
      <el-dialog
        title="选择支付方式"
        :visible.sync="centerDialogVisible"
        width="30%"
        :before-close="closepops"
        center>
        <div class="price-box">
          <div>金额 <span class="money">¥{{price}}</span></div>
          <div class="box-type">
            <el-radio v-model="form.type" label="1" @input="handlePayType">微信</el-radio>
            <el-radio v-model="form.type" label="2" @input="handlePayType">支付宝</el-radio>
          </div>
          <img v-show="qrImgUrl" :src="qrImgUrl" alt="" style="width: 200px; height: 200px;">
        </div>
      </el-dialog>
    </div>
    <!-- 支付成功弹窗 -->
    <div>
      <el-dialog :visible.sync="succesVisible" width="400px" :show-close="false">
        <div class="allpop">
          <div class="succpop">
            <div class="succ1">
              报价信息已发送到您的手机，如有问题 可拨打热线电话
            </div>
            <div class="succ2">{{active.phone}}</div>
            <div class="succ3">上午9：00-12：00，下午13：00-19：00</div>
            <div class="succ4" @click="confirm">确定</div>
            <div class="customer"><img :src="customerImg" alt=""></div>
          </div>
          <div class="succimg">
            <img :src="active.active.image" alt="" />
            <div class="succbtn" @click="onactive(active.active)">进入活动</div>
            <div class="succclose" @click="closepop">
              <img src="@/assets/ic-clear.png" alt="" />
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
  import QRCode from 'qrcode'
  import {
    pinAz,
    carInfo,
    carPrice,
    captche,
    orderAdd,
    orderPay,
    orderInfo,
    carActive,
    customer
  } from "@/untils/api";
  export default {
    name: "CarDetail",
    data() {
      return {
        dialogVisible: false, //报价弹窗
        succesVisible: false, //支付成功弹窗
        form: {
          name: '',
          phone: '',
          code: '',
          pay_type: 2,
          is_scan: 1,
          type: ''
        },
        rules: {
          name: [{
            required: true,
            message: '请输入姓名'
          }],
          phone: [{
            required: true,
            message: '请输入手机号'
          }],
          code: [{
            required: true,
            message: '请输入验证码'
          }]
        },
        info: {},
        content: "发送验证码",
        canClick: true,
        refreshData: null,
        price: 0,
        centerDialogVisible: false,
        qrImgUrl: '',
        orderId: 0,
        active: {
          active: {}
        },
        btnTxt: '立即支付',
        customerImg: ''
      };
    },
    created() {

    },
    mounted() {
      let params = this.$route.query;
      // 详情
      carInfo({
        car_id: params.carId
      }).then(res => {
        if (parseFloat(res.data.suggest) > parseFloat(res.data.last_week_price)) {
          res.data.sign = 'up'
        } else {
          res.data.sign = 'down'
        }
        this.info = res.data
        // 获取价格
        carPrice({
          demio_id: res.data.cate_id
        }).then(ret => {
          this.price = ret.data.price;
          this.btnTxt = parseFloat(ret.data.price) == 0 ? '立即询价' : '立即支付';
        }).catch(r => {
          this.$message.error(r);
        })
        // 获取活动
        carActive({car_id: params.carId}).then(tmp => {
          this.active = tmp.data
        }).catch(err => {
          this.$message.error(err);
        })
      }).catch(err => {
        this.$message.error(err);
      })
    },
    methods: {
      // 关闭弹窗
      closepop() {
        // 清空表单数据
        this.$refs.form.resetFields();
        this.dialogVisible = false;
        this.succesVisible = false;
      },
      //打开弹窗
      showpop() {
        this.dialogVisible = true;
      },
      //支付
      payok() {
        this.$refs.form.validate((valida) => {
          if (valida) {
            // 关闭表单提交
            this.dialogVisible = false;
            if (parseFloat(this.price) == 0) {
              this.handlePayType(1);
            } else {
              // 选择支付方式
              this.centerDialogVisible = true;
            }
          } else {
            this.$message.error('请完善信息');
            return false;
          }
        })
      },
      onactive(e) {
        this.$router.push({
          name: "BrandDetail",
          query: {
            id: e.id,
            type: e.type
          }
        });
      },
      // 1分钟倒计时
      countdowm() {
        let phone = this.form.phone
        const reg = /^[1][0-9]{10}$/;
        if (reg.test(phone)) {
          captche({
            phone: phone
          }).then(res => {
            let totalTime = 60;
            if (!this.canClick) return; //节流
            this.canClick = false;
            this.content = totalTime + "s";
            this.refreshData = window.setInterval(() => {
              totalTime--;
              this.content = totalTime + "s";
              if (totalTime < 0) {
                //当倒计时小于0时清除定时器
                window.clearInterval(this.refreshData); //清除定时器
                this.content = "发送验证码";
                totalTime = 60;
                this.canClick = true; //这里重新开启
              }
            }, 1000);
          }).catch(err => {
            this.$message.error(err);
          })
        } else {
          this.$message.error('请输入正确的手机号');
        }
      },
      // 下单支付
      handlePayType(e = '') {
        clearInterval(this.refreshOrder) //清除定时器
        this.form.type = e;
        let form = this.form;
        if (form.type != '') {
          let store = JSON.parse(localStorage.getItem('form'));
          let newArr = {
            name: form.name,
            phone: form.phone,
            code: form.code,
            brand: store.brand,
            city_id: store.city_id,
            pay_type: form.pay_type,
            car_series: store.car_series, // 车型id
            car_type: store.car_type, // 车系id
            is_scan: form.is_scan,
            type: form.type // 支付方式1微信支付其他支付宝
          }
          // 下单
          orderAdd(newArr).then(ret => {
            // 客服二维码
            customer({order_id: 12}).then(cs => {
              console.log(cs)
              this.customerImg = cs.data.qrcode
            }).catch(err => {
              this.$message.error(err);
            })
            this.orderId = ret.data;
            if (parseFloat(this.price) == 0) {
              this.succesVisible = true;
            } else {
              // 支付
              orderPay({order_id: ret.data, type: form.type, is_scan: form.is_scan}).then(res => {
                // 生成支付二维码
                if (e == 1) {
                  this.getQRcode(res.data.pay.code_url);
                } else {
                  window.open(res.data, "_blank");
                }
                // 实时查询订单
                this.getOrderStatus();
              }).catch(err => {
                this.$message.error(err);
              })
            }
          }).catch(err => {
            this.$message.error(err);
          })
        }
      },
      // 获取支付二维码
      getQRcode(url){
        QRCode.toDataURL(url, { errorCorrectionLevel: 'L', margin: 2, width: 128 }, (err, url) => {
          if (err) this.$message.error(err);
          this.qrImgUrl = url
        })
      },
      // 获取订单支付状态
      getOrderStatus() {
        this.refreshOrder = setInterval(() => {
          let orderId = this.orderId;
          orderInfo({order_id: orderId}).then(res => {
            if (res.data == 1) {
              // 清空表单数据
              this.$refs.form.resetFields();
              clearInterval(this.refreshOrder) //清除定时器
              // 选择支付方式
              this.centerDialogVisible = false;
              this.succesVisible = true;
            }
          }).catch(err => {
            console.log(err)
          })
        }, 1000);
      },
      // 关闭支付弹框 清除定时器
      closepops() {
        clearInterval(this.refreshOrder) //清除定时器
        this.centerDialogVisible = false;
        this.qrImgUrl = ''
      },
      // 关闭成功的弹框
      confirm() {
        // 清空表单数据
        this.$refs.form.resetFields();
        this.succesVisible = false;
      }
    },
  };
</script>
<style lang="scss" scoped>
  ::v-deep .el-dialog__body {
    padding: 0;
  }

  .el-form-item {
    margin: 0 !important;
  }

  .price-box {
    text-align: center;

    .money {
      color: #EB1B34;
    }

    .box-type {
      height: 30px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-size: 16px;
    }
  }

  .banner {
    height: 480px;
    width: 100%;
    background-image: url("@/assets/Rectangle\ 31.png");
    background-size: 100% 100%;

    .banneritem {
      width: 480px;
      height: 480px;
      margin: 0 auto;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .contian {
    margin: 0 auto;
    margin-top: 40px;
    width: 1200px;
    background: #ffffff;

    .dettop {
      margin-top: 24px;

      .dettitle {
        color: rgba(41, 49, 61, 1);
        font-size: 32px;
        font-weight: 600;
        font-family: "PingFang SC";
      }

      .detalign {
        margin-top: 27px;

        .boxmoney {
          color: rgba(235, 27, 52, 1);
          font-size: 26px;
          font-weight: 400;
          font-family: "PingFang SC";

          img {
            margin-left: 12px;
            width: 86px;
            height: 24px;
          }
        }

        .it3 {
          flex: 1;
          padding-left: 40px;
          text-align: left;

          img {
            margin-left: 16px;
            width: 24px;
            height: 24px;
          }
        }

        .btn1 {
          margin-right: 16px;
          width: 120px;
          height: 44px;
          line-height: 44px;
          opacity: 1;
          border: 0 solid rgba(0, 117, 249, 1);
          background: rgba(36, 113, 228, 1);
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          font-weight: 600;
          font-family: "PingFang SC";
          text-align: center;
          cursor: pointer;
        }
      }
    }

    .detlist {
      margin-top: 40px;

      .detbar {
        height: 72px;
        line-height: 72px;
        border: 1px solid #ebedf0;

        .detleft {
          flex: 1;
          color: rgba(150, 151, 153, 1);
          font-size: 16px;
          font-weight: 400;
          font-family: "PingFang SC";
          text-align: center;
          border-right: 1px solid #ebedf0;
        }

        .detright {
          flex: 1;
          color: rgba(41, 49, 61, 1);
          font-size: 16px;
          font-weight: 400;
          font-family: "PingFang SC";
          text-align: center;
        }
      }

      .huibg {
        background: rgba(247, 248, 250, 1);
      }
    }

    .dibg {
      margin-top: 40px;
      height: 200px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .pop {
    width: 600px;
    opacity: 1;
    position: relative;
    background: rgba(255, 255, 255, 1);

    .close {
      position: absolute;
      top: 0;
      right: -70px;
      width: 52px;
      height: 52px;
    }

    .popitem {
      height: 240px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .popdetlist {
      padding: 40px;

      .popdetbar {
        height: 72px;
        line-height: 72px;
        border: 1px solid #ebedf0;

        .popdetleft {
          flex: 1;
          color: rgba(150, 151, 153, 1);
          font-size: 16px;
          font-weight: 400;
          font-family: "PingFang SC";
          text-align: center;
          border-right: 1px solid #ebedf0;
        }

        .popdetright {
          flex: 1;
          color: rgba(41, 49, 61, 1);
          font-size: 16px;
          font-weight: 400;
          font-family: "PingFang SC";
          text-align: center;
        }
      }

      .huibg {
        background-color: #ebedf0;
      }
    }

    .pdettop {
      padding: 0 40px;

      .pdettitle {
        color: rgba(41, 49, 61, 1);
        font-size: 26px;
        font-weight: 600;
        font-family: "PingFang SC";
      }

      .pdetalign {
        margin-top: 27px;

        .pboxmoney {
          color: rgba(235, 27, 52, 1);
          font-size: 26px;
          font-weight: 400;
          font-family: "PingFang SC";

          img {
            margin-left: 12px;
            width: 86px;
            height: 24px;
          }
        }

        .pit3 {
          flex: 1;
          padding-left: 40px;
          text-align: left;

          img {
            margin-left: 16px;
            width: 24px;
            height: 24px;
          }
        }

        .pbtn1 {
          margin-right: 16px;
          width: 120px;
          height: 44px;
          line-height: 44px;
          opacity: 1;
          border: 0 solid rgba(0, 117, 249, 1);
          background: rgba(36, 113, 228, 1);
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          font-weight: 600;
          font-family: "PingFang SC";
          text-align: center;
        }
      }
    }

    .paycs {
      padding: 0 40px 40px 40px;

      .formbar {
        margin-top: 16px;
        position: relative;

        .name {
          width: 50px;
          margin-right: 40px;
          text-align: right;
        }

        .code {
          position: absolute;
          top: 50%;
          right: 32px;
          cursor: pointer;
          transform: translate(0, -50%);
          color: rgba(36, 113, 228, 1);
          font-size: 14px;
          font-weight: 400;
          font-family: "PingFang SC";
        }

        .put {
          width: 432px;
          height: 44px;
          border-radius: 4px;
        }
      }

      .paybtn {
        padding: 0 16px;
        margin: 20px 0 0 auto;
        width: 400px;
        height: 56px;
        opacity: 1;
        background: rgba(41, 49, 61, 1);

        .moneybtn {
          margin: 0 8px;
          color: rgba(255, 255, 255, 1);
          font-size: 22px;
          font-weight: 600;
        }

        .pay {
          width: 145px;
          height: 48px;
          opacity: 1;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          font-weight: 500;
          font-family: "PingFang SC";
          text-align: center;
          line-height: 48px;
          border: 0 solid rgba(0, 117, 249, 1);
          background: rgba(36, 113, 228, 1);
          cursor: pointer;
          animation: throttle 3s step-end forwards;
        }

        // 节流
        .submit:active {
          animation: none;
        }

        @keyframes throttle {
          from {
            pointer-events: none;
          }
          to {
            pointer-events: all;
          }
        }
      }
    }
  }

  .allpop {
    position: relative;
    top: 0;
    left: 0;

    .succpop {
      box-sizing: border-box;
      width: 400px;
      padding: 44px;
      opacity: 1;
      text-align: center;
      background: rgba(255, 255, 255, 1);

      .succ1 {
        margin-top: 24px;
        color: rgba(41, 49, 61, 1);
        font-size: 18px;
        font-weight: 600;
        font-family: "PingFang SC";
      }

      .succ2 {
        margin-top: 32px;
        color: rgba(36, 113, 228, 1);
        font-size: 26px;
        font-weight: 600;
        font-family: "PingFang SC";
        text-align: center;
      }

      .succ3 {
        margin-top: 12px;
        color: rgba(150, 151, 153, 1);
        font-size: 16px;
        font-weight: 400;
        font-family: "PingFang SC";
      }

      .succ4 {
        margin: 0 auto;
        margin-top: 32px;
        width: 160px;
        height: 44px;
        line-height: 44px;
        opacity: 1;
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
        font-weight: 600;
        font-family: "PingFang SC";
        text-align: center;
        border: 0 solid rgba(0, 117, 249, 1);
        background: rgba(36, 113, 228, 1);
        cursor: pointer;
      }

      .customer {
        margin-top: 10px;

        img {
          width: 160px;
          height: 160px;
        }
      }

    }

    .succimg {
      margin-top: 52px;
      width: 400px;
      position: absolute;

      img {
        width: 100%;
        height: 100%;
      }

      .succbtn {
        width: 160px;
        height: 44px;
        opacity: 1;
        position: absolute;
        left: 50%;
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
        font-weight: 500;
        font-family: "PingFang SC";
        text-align: center;
        line-height: 44px;
        transform: translate(-50%, -50%);
        border: 0 solid rgba(0, 117, 249, 1);
        background: rgba(36, 113, 228, 1);
        margin-top: 30px;
      }

      .succclose {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        margin-top: 90px;
      }
    }

  }
</style>
