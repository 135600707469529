<template>
  <div class="Brand">
    <Topnev :checked="'品牌活动'"></Topnev>
    <div class="container">
      <!-- 轮播图 -->
      <div class="banner">
        <el-carousel height="400px">
          <el-carousel-item v-for="(item, index) in bannerList" :key="index">
            <img :src="item.image" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- 主体 -->
      <div class="allbox">
        <div class="leftbox">
          <div class="li" v-for="(item, index) in brandList" :key="index" @click="handleCate(item.id)">
            <div :class="{'li-checked': item.id == pinId}">{{item.name}}</div>
          </div>
        </div>
        <div class="rightbox">
          <div class="flexwrap">
            <div class="box" v-for="(item, index) in activeList" :key="index" @click="handleDetail(item)">
              <div class="box-cont">
                <img :src="item.img" alt="" />
                <div class="txt">
                  {{item.name}}
                </div>
                <div class="time">发布时间：{{item.createtime}}</div>
              </div>
            </div>
          </div>
          <div class="pagebox">
            <span class="pagebox_left">共{{lastPage}}页</span>
            <div class="pagebox_right">
              <el-pagination :current-page="currentPage" background layout="prev, pager, next, jumper" :total="total"
                @current-change="handleCurrentChange" :page-size="9">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Botnev></Botnev>
  </div>
</template>
<script>
  import {
    pinAz,
    banner,
    active,
    brand
  } from "@/untils/api";
  export default {
    name: "BrandView",
    data() {
      return {
        bannerList: [],
        activeList: [],
        total: 0,
        currentPage: 1,
        lastPage: 1,
        pinId: 0,
        brandList: [{
          "id": 0,
          "name": "全部",
          "image": "",
          "mu": "Q"
        }]
      };
    },
    created() {
      // 轮播图
      banner({
        type: 1
      }).then(res => {
        this.bannerList = res.data
      }).catch(err => {
        this.$message.error(err);
      })
      // 活动
      this.getActiveList();
      // 品牌
      brand().then(ret => {
        this.brandList = this.brandList.concat(ret.data)
      }).catch(err => {
        this.$message.error(err);
      })
    },
    mounted() {},
    methods: {
      // 分类筛选
      handleCate(id) {
        this.pinId = id;
        this.getActiveList();
      },
      // 跳转详情
      handleDetail(e) {
        this.$router.push({
          name: "BrandDetail",
          query: {
            id: e.id,
            type: e.type
          }
        });
      },
      // 分页
      handleCurrentChange(val) {
        this.currentPage = val
        this.getActiveList()
      },
      // 获取活动列表
      getActiveList() {
        // 活动
        let page = this.currentPage
        let pinId = this.pinId
        active({
          page: page,
          list: 9,
          pin_id: pinId
        }).then(ret => {
          this.activeList = ret.data.data
          this.total = ret.data.total
          this.lastPage = ret.data.last_page
        }).catch(err => {
          this.$message.error(err);
        })
      }
    }
  };
</script>
<style lang="scss" scoped>
  .Brand {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #EEEFF4;

    .li-checked {
      width: 192px;
      height: 54px;
      border-radius: 8px;
      background-color: #2471E4;
      color: #fff;
      font-size: 16px;
    }

    .container {
      width: 1200px;
      margin: 0 auto;
      flex: 1 0 auto;

      .banner {
        margin-top: 24px;
        border-radius: 12px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 12px;
        }
      }

      .allbox {
        width: 100%;
        display: flex;
        margin: 24px 0;
        justify-content: space-between;

        .leftbox {
          width: 216px;
          height: 1206px;
          padding-bottom: 12px;
          border-radius: 8px;
          opacity: 1;
          background: rgba(255, 255, 255, 1);
          overflow: auto;
          -ms-overflow-style: none;
          scrollbar-width: none;

          &::-webkit-scrollbar {
            display: none;
          }

          .li {
            width: 192px;
            margin: auto;
            height: 54px;
            line-height: 54px;
            text-align: center;
            margin-top: 12px;
            cursor: pointer;
          }
        }

        .rightbox {
          width: calc(100% - 240px);

          .flexwrap {
            width: 100%;
            display: grid;
            grid-template-rows: repeat(3, 402px);
            grid-template-columns: repeat(3, 304px);
            grid-column-gap: 24px;
            grid-row-gap: 24px;

            .box {
              width: 304px;
              height: 406px;
              opacity: 1;
              color: rgba(41, 49, 61, 1);
              font-size: 16px;
              font-weight: 500;
              font-family: "PingFang SC";
              background-color: #fff;
              border-radius: 8px;

              .box-cont {
                margin: auto;
                padding: 12px;

                img {
                  width: 100%;
                  height: 280px;
                }

                .txt {
                  margin-top: 24px;
                  color: rgba(41, 49, 61, 1);
                  font-size: 16px;
                  font-weight: 600;
                  text-overflow: -o-ellipsis-lastline;
                  overflow: hidden;				//溢出内容隐藏
                  text-overflow: ellipsis;		//文本溢出部分用省略号表示
                  display: -webkit-box;			//特别显示模式
                  -webkit-line-clamp: 2;			//行数
                  line-clamp: 2;
                  -webkit-box-orient: vertical;	//盒子中内容竖直排列
                }

                .time {
                  margin-top: 8px;
                  color: rgba(100, 101, 102, 1);
                  font-size: 14px;
                  font-weight: 400;
                }

                &:nth-child(3n) {
                  margin-right: 0;
                }
              }
            }
          }

          .pagebox {
            margin: 0 auto;
            height: 120px;
            box-sizing: border-box;
            padding: 0 24px 0 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 40px;

            &_left {
              color: rgba(0, 0, 0, 0.6);
              font-size: 14px;
              font-weight: 400;
              font-family: "PingFang SC";
              text-align: left;
              line-height: 22px;
            }
          }
        }
      }
    }
  }
</style>
