<template>
  <div class="Active">
    <Topnev></Topnev>
    <div class="container ">
      <div class="flexwrap">
        <div class="box" v-for="(item, index) in 8" :key="index">
          <img src="@/assets/banner.png" alt="" />
          <div class="txt">
            新车：售价49.9万元起，腾势D9，岚图梦想家对手，极客009正式上市
          </div>
          <div class="time">发布时间：2022.10.18</div>
        </div>
      </div>
    </div>
    <Botnev></Botnev>
  </div>
</template>
  <script>
import { pinAz } from "@/untils/api";
export default {
  name: "Active",
  data() {
    return {};
  },
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.Active {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.container {
  width: 1200px;
  margin: 0 auto;
  flex: 1 0 auto;
  .box {
    margin-top: 24px;
    margin-right: 24px;
    width: 384px;
    height: 318px;
    opacity: 1;
    color: rgba(41, 49, 61, 1);
    font-size: 16px;
    font-weight: 500;
    font-family: "PingFang SC";
    img {
      width: 100%;
      height: 216px;
    }
    .txt {
      margin-top: 24px;
      color: rgba(41, 49, 61, 1);
      font-size: 16px;
      font-weight: 600;
      font-family: "PingFang SC";
    }
    .time {
      margin-top: 8px;
      color: rgba(100, 101, 102, 1);
      font-size: 14px;
      font-weight: 400;
      font-family: "PingFang SC";
    }
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}
</style>